<div class="relative flex flex-col gap-[24px] max-w-[630px] p-6 md:p-8">
  <ng-container *ngIf="userType === 'user'; else therapistContent">
    <app-button
      class="w-full sm:w-auto"
      (click)="openLink('https://r30z08t68i7.typeform.com/TherAppySeeker')">
      {{ 'LAUNCHLIST.FIND_THERAPIST' | translate }}
    </app-button>
  </ng-container>

  <ng-template #therapistContent>
    <app-button
      class="w-full sm:w-auto"
      (click)="openLink('https://r30z08t68i7.typeform.com/NewTherapist')">
      {{ 'LAUNCHLIST.JOIN_NETWORK' | translate }}
    </app-button>
  </ng-template>
</div>
